import { useEffect, memo, useState } from 'react'
import type { FC, Dispatch, SetStateAction } from 'react'
import type { NextPage } from 'next'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { HeadTag, SlidersHome } from '@/components'
import { callAPI } from '@/api'
import { callLocales } from '@/i18n'
import { mq, getRelative } from '@/styles'
import { HomeHeader,BestsellerSection, BrandsSection, RoundHighlightSection } from '../components/'
import { Layout } from '../components'
import { SectionProductDetail, TypeProductDetailVariant } from '../components/shop'
import useCustomer from '@custom-bigcommerce/storefront-data-hooks/use-customer'


type TypeHomePage = {
  data: any,
  staticText: any,
  onLoad?: any,
  isLoading?: any,
  user?: any,
  isInvalid?: any,
  isAuthorize?: any,
}

const Main = styled.main`
  [class*='ContentVideo__Figure'] {
    padding: 0 ${getRelative(20, 'mobile')};

    ${mq.greaterThan('tablet')`
      padding: 0;
    `}
  }
`

export type productDetailType = [
  TypeProductDetailVariant,
  Dispatch<SetStateAction<TypeProductDetailVariant>>
]


export async function getServerSideProps({ locale }) {
  const [data, error] = await callAPI({ type: 'home',  params: { locale } })
  const [layoutData, layoutError] = await callAPI({ type: 'layout',  params: { locale } })
  if (error || layoutError) return { notFound: true }

  const [locales, layoutLocale] = await callLocales({ type: 'home', locale })

  return {
    props: {
      data:data,
      layout:layoutData,
      locales,
      layoutLocale
    },
    // revalidate: 120, // In seconds
  }
}
const usualPurchasesData = {
  slug : {
    es: '/account/usual-purchases',
    fr: '/account/usual-purchases',
    en: '/account/usual-purchases'
  },
  name: {
    es: 'Mis habituales',
    en: 'My favourites',
    fr: 'Mon historique'
  }
}

const NEXT_LOCALE = process.env.NEXT_PUBLIC_LOCALE
const HomePage: NextPage<TypeHomePage> & { Layout?:FC } = memo(({ data }) => {
  const { seo, brands, top_sellers, new:nuovissimo, links, promotions } = data
  const { locale, defaultLocale } = useRouter() || { locale: NEXT_LOCALE, defaultLocale: NEXT_LOCALE }
  const { data: customerData }  = useCustomer()

  const [usualPurchases, setUsualPurchases] = useState({
    category: {
      name: usualPurchasesData.name[NEXT_LOCALE],
      slug :usualPurchasesData.slug[NEXT_LOCALE]
    },
    products: Array.from([])
  })

  const [productDetailVisible, setProductDetailVisible] = useState(false)
  const [productDetailed, setProductDetailed]:productDetailType = useState(null)
  const displayProductDetail = () => {
    if(!productDetailVisible) setProductDetailVisible(true)
  }
  const hideProductDetail = () => {
    if(productDetailVisible) setProductDetailVisible(false)
    if(typeof window === 'object') {
      const url = locale !== NEXT_LOCALE ? '/' + locale : '/'
      window.history.pushState('', '', url)
    }
  }

  useEffect(() => {
    const customer_id = customerData?.entityId
    async function getUsualPurchases() {
      const [data, error] = await callAPI({ type: 'user-usual-purchases_limit', params: { customer_id, locale }})
      if(!error) setUsualPurchases({
        category: {
          name: usualPurchasesData.name[NEXT_LOCALE],
          slug :usualPurchasesData.slug[NEXT_LOCALE]
        },
        products: data.data
      })
    }

    if(customer_id) getUsualPurchases()
  }, [customerData])

  return (
    <>
      <HeadTag data={seo} jsonld={{ data, pageType: 'home' }} slug={''} defaultLocale={defaultLocale} />
      {Array.isArray(data?.banners) ? <HomeHeader data={data?.banners}/> : <></>}
      <Main>
        <BestsellerSection />
        {
          usualPurchases !== null && usualPurchases.products.length > 0
          ?
            <SlidersHome data={ usualPurchases} dataKey={'usual-purchases'} setProductDetailed={setProductDetailed} displayProductDetail={displayProductDetail}/>
          :
          <>
            { top_sellers !== null && top_sellers.products.length > 0 && <SlidersHome data={top_sellers} dataKey={'top-sellers'} setProductDetailed={setProductDetailed} displayProductDetail={displayProductDetail}/> }
          </>
        }
        { links && links.length > 0 && <RoundHighlightSection data={links}/> }
        { promotions !== null  && promotions.products.length > 0 && <SlidersHome data={promotions} setProductDetailed={setProductDetailed} displayProductDetail={displayProductDetail}/>}
        { nuovissimo !== null && nuovissimo.products.length > 0  && <SlidersHome data={nuovissimo} setProductDetailed={setProductDetailed} displayProductDetail={displayProductDetail}/>}
        { brands.length > 0 &&  <BrandsSection brands={brands} />}
        <SectionProductDetail productDetailVisible={productDetailVisible} hideProductDetail={hideProductDetail} variant={productDetailed} className='not-in-grid' />
      </Main>
    </>
  )
})

HomePage.Layout = Layout
export default HomePage
